import React from "react";
import CatImage from "../resources/images/businessman-logging-his-tablet1.jpg"
import TextField from '@mui/material/TextField';
import { Grid, Paper, Stack } from '@mui/material';
import welImage from "../resources/images/Welcome.png"
import { Formik } from 'formik';
import { loginAction } from "../components/Actions/AuthAction";
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from "@mui/lab";

export default function BasicTextFields() {

  /* Navigate Moving to other page like here login to dashboard */
  const navigate = useNavigate()

  /* validationSchema for validate */

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required'),
    password: Yup.string()
      .required('Password is required'),
  })

  return (
    <Stack
      className="body-img"
      display={"flex"}
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{ height: '100vh' }}>
      <Paper

        elevation={10}
        sx={{
          height: 500,
          borderRadius: 5,
          background: "../src/resources/images/aaaa.jpg"

        }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <img
              alt="complex"
              src={CatImage}
              style={{
                borderRadius: 20,
                width: '100%',
                height: 500
              }} />

          </Grid>
          <Grid item xs={6} direction="column">
            <Formik
              initialValues={{
                email: '',
                password: ''
              }}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={(values, actions) => { loginAction(values, navigate, actions) }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                /* and other goodies */
              }) => (
                <Stack
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    px: 5,
                    height: 500
                  }}
                >
                  <img
                    alt="just"
                    src={welImage}
                    style={{
                      width: 200,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  />
                  <h3>Welcome To  Sysm Technology</h3>
                  <TextField
                    name="email"
                    id="outlined-basic"
                    label="Enter Your Email"
                    variant="outlined"
                    size='small'
                    type={"email"}
                    fullWidth
                    value={values.email}
                    onChange={(e) => { setFieldValue('email', e.target.value) }}
                  />
                  {errors.email && <span style={{ color: 'red' }}>{errors.email && touched.email ? errors.email : ""}</span>}
                  <TextField
                    name="password"
                    id="outline-basic"
                    label="Password"
                    variant="outlined"
                    size="small"
                    type={"password"}
                    fullWidth
                    value={values.password}
                    onChange={(e) => { setFieldValue('password', e.target.value) }}
                  />
                  {errors.password && <span style={{ color: 'red' }}>{errors.password && touched.password ? errors.password : ""}</span>}
                  <a href="../forgot">Forgot Password</a>
                  <LoadingButton
                    variant="contained"
                    sx={{
                      width: 200,
                      height: 40,
                      backgroundColor: "#2da897"
                    }}
                    onClick={handleSubmit}
                    loading={isSubmitting}  
                  >LOG IN</LoadingButton>
                  <h3>New User :<a href="../Signup">Sign UP</a></h3>
                </Stack>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Paper>
    </Stack>
  );
}