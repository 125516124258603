import axios from 'axios';
import { BASE_URL } from './Api';
import { toast } from 'react-toastify';
import { setRecoil } from 'recoil-nexus';
import { PaymentlisttState } from '../../Actions';

export const getPaymentData = () => {
  const dataToken = JSON.parse(localStorage.getItem('token'));
  // console.log(response.data.data)
  const config = {
    headers: {
      'Authorization': `Bearer ${dataToken}`
    }
  }
  axios.get(`${BASE_URL}/payment`, config)
    .then((response) => {
      setRecoil(PaymentlisttState,response.data.data)
    })
    .catch((error) => {
      // console.log("Get Contact", error);
    })
}

export const submitData = (values, actions, onClose) => {

  const tokenData = JSON.parse(localStorage.getItem('token'));
  //  console.log(everyData);
  axios.post(`${BASE_URL}/payment`, values, {
    headers: {
      'Authorization': `Bearer ${tokenData}`
    }
  })
  
    .then(function (response) {
      //console.log("success",response.data.data);
      if (response.data.status) {
        actions.setSubmitting(false)
        actions.resetForm()
        onClose()
        setRecoil(PaymentlisttState,response.data.data)
        toast.success(response.data.message);
        console.log(values);

      }
      // console.log(response.data.data);
    })
}

export const handlePaymentDelete = (id) => {
  const dataToken = JSON.parse(localStorage.getItem('token'));

  axios.delete(`${BASE_URL}/payment/${id}`,
    {
      headers: {
        'Authorization': `Bearer ${dataToken}`
      }
    })
    .then(function (response) {
      if (response.data.status) {
        toast.success(response.data.message);
        console.log(response.data.data)
        setRecoil(PaymentlisttState,response.data.data)
      } else {
        toast.error(response.data.message);
      }
      // console.log(response.data.status)

    })
}

