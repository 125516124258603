import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Dialog } from '@mui/material';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { loadingState } from '../Actions';

export default function AppLoader() {
  const loadingData = useRecoilValue(loadingState)
  const resetLoader = useResetRecoilState(loadingState)
  const isLoading = loadingData?.type === "screen" && loadingData?.status
  
  return (
    <Dialog onClose={() => resetLoader() } open={isLoading}>
    <Box sx={{ display: 'flex', p: 1 }}>
      <CircularProgress size={30} />
    </Box>
    </Dialog>
  );
}
