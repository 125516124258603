import axios from 'axios';
import { BASE_URL } from './Api';
import { toast } from 'react-toastify';
import { resetRecoil, setRecoil } from 'recoil-nexus';
import { projectState, loadingState } from '../../Actions';
import  AxiosApi  from "../../Actions/AxiosApi";

export const getProjectData = () => {
  setRecoil(loadingState, {
    type: "screen",
    status: true
})
  const dataToken = JSON.parse(localStorage.getItem('token'));
  // console.log(response.data.data)
  const config = {
      headers: {
          'Authorization': `Bearer ${dataToken}`
      }
  }
  axios.get(`${BASE_URL}/project`, config)
      .then((response) => {   
        setRecoil(projectState, response.data.data)
        resetRecoil(loadingState)
      })
      .catch((error) => {
        resetRecoil(loadingState)
      })
}
export const submitData = (values, actions, onClose) => {
  actions.setSubmitting(true)
  AxiosApi.post("project", values)
    .then(function ({ data }) {
      if (data.status) {
        actions.setSubmitting(false)
              actions.resetForm()
              onClose()
              getProjectData()
              toast.success(data.message);
      }
      //  else {    
      //   actions.setErrors({ email: data.message })
      // }
    })
    .catch(error => {
      console.log("Get Profile", error);
      actions.setSubmitting(false)
      actions.setErrors({ email: "Something went wrong. Please try again." })
    })

  // const tokenData = JSON.parse(localStorage.getItem('token'));
  // axios.post(`${BASE_URL}/project`, values, {
  //   headers: {
  //     'Authorization': `Bearer ${tokenData}`
  //   }
  // })
  //   .then(function (response) {
  //     // console.log("success",response.data.status);
  //     if (response.data.status) {
  //       actions.setSubmitting(false)
  //       actions.resetForm()
  //       onClose()
  //       getProjectData()
  //       toast.success(response.data.message);
  //     }
  //     // console.log(response.data.data);
  //   })
}
export const handleProjectEdit = (values, actions, onClose) => {
  const tokenData = JSON.parse(localStorage.getItem('token'));
  axios.patch(`${BASE_URL}/project/${values?.id}`, values, {
      headers: {
          'Authorization': `Bearer ${tokenData}`
      }
  })
      .then(function (response) {
          if (response.data.status) {
              actions.resetForm()
              getProjectData()
              onClose()
              toast.success(response.data.message);
          } 
          console.log(response.data.status)

      }).catch(err => {
          // setError({ name: "Something went wrong" })
          // console.log("Custom Error", err.response.status)
      })
}
export const handleProjectDelete = (id) => {
  const dataToken = JSON.parse(localStorage.getItem('token'));

  axios.delete(`${BASE_URL}/project/${id}`,
    {
      headers: {
        'Authorization': `Bearer ${dataToken}`
      }
    })
    .then(function (response) {
      if (response.data.status) {
        toast.success(response.data.message);
        getProjectData()
        //actions.resetForm()
      } else {
        toast.error(response.data.message);
      }
      // console.log(response.data.status)

    })
}