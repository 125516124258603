
import axios from 'axios';
import { BASE_URL } from './Api';
import { toast } from 'react-toastify';


export const getData = (setData) => {
    const dataToken = JSON.parse(localStorage.getItem('token'));
    // console.log(dataToken)
    let config = {
        headers: {
            'Authorization': `Bearer ${dataToken}`
        }
    }
    axios.get(`${BASE_URL}/payable-account`, config)
        .then((response) => {
            setData(response.data.data)
        })
        .catch((error) => {
            // console.log("Get Contact", error);
        })
}

export const submitdata = (values, actions, setOpenAddModal, setData) => {

    const tokenData = JSON.parse(localStorage.getItem('token'));
    // console.log(everyData);
    axios.post(`${BASE_URL}/payable-account`, values, {
        headers: {
            'Authorization': `Bearer ${tokenData}`
        }
    })
        .then(function (response) {
            //console.log(response.data.data);
            if (response.data.status) {
                setData(response.data.data)
                toast.success(response.data.message);
                setOpenAddModal(false)  
               
            }
            console.log(response.data.data
            );
        })
}


export const handleDelete = (id, setData) => {
    const dataToken = JSON.parse(localStorage.getItem('token'));

    axios.delete(`${BASE_URL}/payable-account/${id}`,
        {
            headers: {
                'Authorization': `Bearer ${dataToken}`
            }
        })
        .then(function (response) {
            if (response.data.status) {
                toast.success(response.data.message);
                setData(response.data.data)
            }else{
                toast.error(response.data.message);
            }
            
        })
}
