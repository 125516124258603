import React, { useState, useEffect } from "react";
import moment from 'moment';
import ProjectForm from "../../components/ProjectForm";
import DataTable from 'react-data-table-component';
import { Button, InputAdornment, OutlinedInput, ButtonGroup } from '@mui/material';
import { LxIcon } from '../../resources/LxIcon';
import { getCompanyData } from "../../components/Actions/CompanyActions";
import { getProjectData, handleProjectDelete } from "../../../src/components/Actions/ProjectAction";
import ConfirmDialog from "../../components/DeleteDialog";
import { projectState } from "../../Actions";
import { useRecoilValue } from "recoil";
import { companyState } from "../../Actions";

export default function BasicTable() {
  const projectData = useRecoilValue(projectState)
  console.log(projectData);
  const [editData, setEditData] = useState({});
  const [openAddModal, setOpenAddModal] = useState(false);
  const [OpenPopup, setOpenPopup] = useState(false);
  const [searchInput, setSearchInput] = React.useState('');
  const [DeleteId, setDeleteId] = useState('');

  useEffect(() => {
    getProjectData()
    getCompanyData(companyState)
  }, [])

  const columns = [
    {
      name: '#',
      selector: (row, index) => index + 1,

      width: "5%"
    },
    {
      name: 'Project Name',
      selector: row => row.p_name,
      sortable: true,
      paddingleft: "-10"
    },
    {
      name: 'Company Name',
      selector: row => row?.company_info?.c_name,
      sortable: true,
    },
    {
      name: 'Project Cost',
      selector: row => row.p_cost,
      sortable: true,
      width: "10%"
    },
    {
      name: 'Recived Amount',
      selector: row => row.receivable_amount,
      sortable: true,
      width: "10%"
    },
    {
      name: 'Due Amount',
      selector: row => row.due_amount,
      sortable: true,
      width: "10%"
    },
    {
      name: 'Confirmation Date',
      selector: row => moment(row.confirmation_date, 'YYYY-MM-DD').format("DD-MM-YYYY"),
      sortable: true,
      width: "12%"
    },
    {
      name: 'Deadline Date',
      selector: row => moment(row.deadline, 'YYYY-MM-DD').format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: ' Notes',
      selector: row => row.note,
      sortable: true,
      width: "12%"
    },
    {
      name: 'Action',
      selector: row =>
        <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
          <Button onClick={() => {
            setEditData(projectData.find((a) => a.id === row.id))
            setOpenAddModal(true)
          }}><LxIcon name="FaRegEdit" type="fa" /></Button>
          <Button onClick={() => {
            setDeleteId(row.id)
            setOpenPopup(true)
          }}
            color="error"><LxIcon name="BiTrash" type="bi" /></Button>
        </ButtonGroup>,
      sortable: false,
      width: "10%"
    },
  ]
  const customStyles = {
    header: {
      style: {
        minHeight: '40px',
      },
    },
    headRow: {
      style: {
        height: '10px',
        minHeight: '40px',
        backgroundColor: "#57b8c1"
      },
    },
    headCells: {
      style: {
        fontSize: '12px',
        fontWeight: '600',
        padding: '6px'
      },
    }
  }
  const conditionalRowStyles = [
    {
      when: row => row.UserStat === 'Inactive',
      style: {
        backgroundColor: '#f3f3f3',
        color: 'rgb(132 132 132 / 87%)'
      },
    }
  ]

  const actions =
    <Button
      sx={{
        backgroundColor: "#57b8c1"
      }}
      className='btn-success'
      variant="contained"
      size='small'
      onClick={() => {
        setOpenAddModal(true)
        setEditData({})
      }}
      startIcon={<LxIcon name="FaPlus" type="fa" size={15} />}
    >
      Add New
    </Button>
  const searchComponent = <OutlinedInput
    placeholder="Search"
    value={searchInput}
    onChange={(e) => setSearchInput(e.target.value)}
    size="small"
    endAdornment={<InputAdornment position="end"><LxIcon name="MdOutlineSearch" type="md" /></InputAdornment>}
    variant="outlined"
  />
  const filteredItems = projectData?.filter(item => (
    item?.p_name.toLowerCase().includes(searchInput.toLowerCase())
  ));
  return (
    <>
      <DataTable
        title='Project Details'
        actions={actions}
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
        columns={columns}
        data={filteredItems}
        fixedHeader
        persistTableHead
        subHeader
        subHeaderComponent={searchComponent}
        paginationResetDefaultPage={true}
        pagination={true}
        currentPage={1}
        paginationPerPage={10}
        paginationComponentOptions={{
          selectAllRowsItem: true,
          selectAllRowsItemText: 'All'
        }}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
      />

      <ProjectForm
        openAddModal={openAddModal}
        editData={editData}
        onClose={() => setOpenAddModal(false)}
      />
      <ConfirmDialog
        open={OpenPopup}
        onOpen={() => setOpenPopup(false)}
        onClick={() => handleProjectDelete(DeleteId)} />
    </>

  );
}

