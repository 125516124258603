import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './App.css';
import Dashboard from "./pages/App/dashboard";
import { deepPurple } from "@mui/material/colors";
import AppLayout from "./components/Layouts/AppLayout.js";
import Login from "./pages/login";
import Projects from "./pages/App/Projects";
import Statements from "./pages/App/Statements";
import ExpenseType from "./pages/App/ExpenseType";
import Expenses from "./pages/App/Expenses";
import Company from "./pages/App/Company";
import Payment from "./pages/App/Payment";
import PayableAccount from "./pages/App/PayableAccount";
import Signup from "./pages/Signup";
import PrivateRoute from "./resources/PrivateRoute";
import Forgot from "./pages/forgot";
import 'react-toastify/dist/ReactToastify.css';
const theme = createTheme({
  palette: {
    primary: {
      main: deepPurple[700],
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
  },
});

function App() {
  return (
    <RecoilRoot>
      <RecoilNexus />
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot" element={<Forgot />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/" element={<PrivateRoute><AppLayout /></PrivateRoute>}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path='/projects' element={<Projects />} />
              <Route path='/statements' element={<Statements />} />
              <Route path='/expense-type' element={<ExpenseType />} />
              <Route path='/expenses' element={<Expenses />} />
              <Route path='/company' element={<Company />} />
              <Route path='/payment' element={<Payment />} />
              <Route path='/payable-account' element={<PayableAccount />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </RecoilRoot>
  );
}

export default App;
