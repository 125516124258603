import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
      const tokenData = localStorage.getItem('token')
      const location = useLocation()
      if (!tokenData) {
            return <Navigate to="/login" state={{ from: location }} replace />
      }
      return children;
}

export default PrivateRoute;