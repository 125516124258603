import { atom } from 'recoil';

const localStorageEffect = key => ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
        isReset
            ? localStorage.removeItem(key)
            : localStorage.setItem(key, JSON.stringify(newValue));
    });
};

export const layoutState = atom({
    key: 'layoutState',
    default: true,
    effects_UNSTABLE: [
        localStorageEffect("toggleMenu"),
    ],
});

export const loadingState = atom({
    key: 'loadingState',
    default: {
        type: "",
        status: false
    }
});


export const authState = atom({
    key: 'authState',
    default: true,
    effects_UNSTABLE: [
        localStorageEffect("token"),
    ],
});

export const companyState = atom({
    key: 'companyState',
    default: []
});
export const projectState = atom({
    key: 'projectState',
    default: []
});
export const ProjectEditState = atom({
    key: 'ProjectEditState',
    default: {}
});

export const PaymentlisttState = atom({
    key: 'PaymentlisttState',
    default: []
});
export const ExpensesState = atom({
    key: 'ExpensesState',
    default: []
});
export const ExpensesTypeState = atom({
    key: 'ExpensesTypeState',
    default: []
});
export const ProfileState = atom({
    key: 'ProfileState',
    default: []
});