import React from "react";
import Select from '@mui/material/Select';
import FormControl from "@mui/material/FormControl";
import { IoPersonSharp } from "react-icons/io5";
import Box from "@mui/material/Box";
import { LoadingButton } from "@mui/lab";
import { Formik } from 'formik';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Button, InputAdornment, Stack } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { BiRupee } from "react-icons/bi";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import moment from 'moment';
import * as Yup from 'yup'
import { handleEditSubmit, submitdata } from "../components/Actions/ExpensesAction";
import { ExpensesTypeState } from "../Actions";
import { useRecoilValue } from "recoil";
import Grid from '@mui/material/Grid';

const ExpensesForm = (props) => {
    const { openAddModal, onClose, editData } = props;
    const expenseTypeData = useRecoilValue(ExpensesTypeState)
    const validationSchema = Yup.object().shape({
        expense_type_id: Yup.string()
            .required('Title is required'),
        paid_to: Yup.string()
            .required('This field is required'),
        paid_by: Yup.string()
            .required('This field is required'),
        amount: Yup.string()
            .required('Amount is required'),

        date: Yup.date()
            .required('Date is required'),

        note: Yup.string()
            .required('Note is required'),
    })
    // console.log(editData)
    return (
        <Stack spacing={2} direction="row">
            <Dialog
                open={openAddModal}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Formik
                    initialValues={{
                        title: editData?.title,
                        id: editData?.id,
                        expense_type_id: editData?.expense_type_id,
                        paid_to: editData?.paid_to,
                        paid_by: editData?.paid_by,
                        amount: editData?.amount,
                        // date: moment().format('YYYY-MM-DD'), {before edit date format}
                        date: editData?.date ? editData?.date : moment().format('YYYY-MM-DD'),
                        note: editData?.note,
                    }}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={(values, actions) => {
                       //console.log(values);
                        if(values?.id){
                            handleEditSubmit(values, actions, onClose)   
                        }else{
                            submitdata(values, actions, onClose)
                        }
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        /* and other goodies */
                    }) => (
                        <>
                            <DialogTitle id="alert-dialog-title">
                                {"Enter Your Expenses Details"}
                            </DialogTitle>
                            <DialogContent>
                                <Grid container spacing={1} style={{ marginTop: 10 }}>
                                    <Grid item xs={6} md={16}>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Expense Title</InputLabel>
                                                <Select
                                                    label="transaction"
                                                    name="title"
                                                    value={values.expense_type_id}
                                                    onChange={(e) => {
                                                        setFieldValue('title', e.target.value)
                                                        setFieldValue('expense_type_id', e.target.value)

                                                    }}
                                                >
                                                    {expenseTypeData?.map((expensestype) =>
                                                        <MenuItem value={expensestype.id} >{expensestype.title}</MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        {errors.title && <span style={{ color: 'red' }}>{errors.title && touched.title ? errors.title : ""}</span>}
                                    </Grid>

                                    <Grid item xs={6} md={4}>
                                        <TextField id="Paid to"
                                            name="paid_to"
                                            label="Paid to"
                                            variant="outlined"
                                            type="string"
                                            fullWidth
                                            sx={{
                                                py: 1
                                            }}
                                            value={values.paid_to}
                                            onChange={(e) => {
                                                setFieldValue('paid_to', e.target.value)
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <IoPersonSharp />
                                                    </InputAdornment>
                                                ),
                                            }}

                                        />
                                        {errors.paid_to && <span style={{ color: 'red' }}>{errors.paid_to && touched.paid_to ? errors.paid_to : ""}</span>}
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <TextField id="Paid by"
                                            name="paid_by"
                                            label="Paid by"
                                            variant="outlined"
                                            type="string"
                                            fullWidth
                                            sx={{
                                                py: 1
                                            }}
                                            value={values.paid_by}
                                            onChange={(e) => {
                                                setFieldValue('paid_by', e.target.value)
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <IoPersonSharp />
                                                    </InputAdornment>
                                                ),
                                            }}

                                        />
                                        {errors.paid_by && <span style={{ color: 'red' }}>{errors.paid_by && touched.paid_by ? errors.paid_by : ""}</span>}
                                    </Grid>
                                    <Grid item xs={6} md={6}>

                                        <TextField id="Amount"
                                            label=" Amount"
                                            name="amount"
                                            type={"number"}
                                            value={values.amount}
                                            onChange={(e) => { setFieldValue('amount', e.target.value) }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <BiRupee />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {errors.amount && <span style={{ color: 'red' }}>{errors.amount && touched.amount ? errors.amount : ""}</span>}
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <LocalizationProvider dateAdapter={AdapterMoment} >
                                            <DesktopDatePicker
                                                label="Date"
                                                name="date"
                                                inputFormat="DD-MM-yyyy"
                                                value={values.date}
                                                onChange={(date) => {
                                                    //console.log(date) 
                                                    setFieldValue('date', moment(date).format('YYYY-MM-DD'))
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                        {errors.date && <span style={{ color: 'red' }}>{errors.date && touched.date ? errors.date : ""}</span>}
                                    </Grid>
                                    <Grid item xs={6} md={16}>
                                        <TextareaAutosize
                                            aria-label="minimum height"
                                            minRows={5}
                                            placeholder="Note"
                                            style={{ width: '100%' }}
                                            name="note"
                                            value={values.note}
                                            onChange={(e) => { setFieldValue('note', e.target.value) }}
                                        />
                                        {errors.note && <span style={{ color: 'red' }}>{errors.note && touched.note ? errors.note : ""}</span>}
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions
                                style={{ marginRight: 20 }}
                            >
                                <Button onClick={onClose} color="error" autoFocus variant="outlined"
                                    sx={{
                                        width: 60,
                                        height: 40
                                    }}
                                >Close</Button>
                                <LoadingButton
                                    onClick={handleSubmit}
                                    color="success"
                                    autoFocus
                                    variant="contained"
                                    // sx={{
                                    //     width: 60,
                                    //     height: 40
                                    // }}
                                >
                                {values?.id ? 'Update' : 'Add'}
                                </LoadingButton>
                            </DialogActions>
                        </>
                    )}
                </Formik>
            </Dialog>
        </Stack>
    )
}
export default ExpensesForm;