import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik } from 'formik';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Stack } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { IoCheckmarkSharp, IoClose, IoPersonSharp } from "react-icons/io5";
import { BsBank2 } from "react-icons/bs";
import {submitdata} from './Actions/PayableActions';
import * as Yup from 'yup'

const DialogForm = (props) => {
    const { open, onClose, setData, editData} = props;

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required'),
        account_number: Yup.number()
            .required('Mobile number is required')
            .typeError('Please fill with numbers'),
        ifsc_code: Yup.string()
            .required('Message is required'),
        bank_name: Yup.string()
            .required('Message is required'),
        branch_name: Yup.string()
            .required('Branch name is required'),
    })
    return (
        <Dialog open={open} onClose={onClose}>
            <Formik
                initialValues={{
                       id:editData?.id,
                    name:editData?.name,
                    account_number:editData?.account_number,
                    ifsc_code:editData?.ifsc_code,
                    bank_name:editData?.bank_name,
                    branch_name:editData?.branch_name
                }}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    submitdata(values, actions, onClose, setData)
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    // / and other goodies /
                }) => (
                    <Stack
                        display={"flex"}
                        direction={"column"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        spacing={2}
                        sx={{
                            backgroundColor: "#Cyan"

                        }}>
                        <DialogTitle>Add new Account Details</DialogTitle>
                        {/* <span style={{ color: 'red' }}>{apiMessage}</span> */}
                        <DialogContent
                            sx={{
                                margin: 10,

                            }}
                        >
                            <TextField id="Name"
                                name="name"
                                label="Account Holder Name"
                                variant="outlined"
                                type="string"
                                fullWidth
                                sx={{
                                    py: 1
                                }}
                                value={values.name}
                                onChange={(e) => {
                                    setFieldValue('name', e.target.value)
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IoPersonSharp />
                                        </InputAdornment>
                                    ),
                                }}

                            />
                            {errors.name && <span style={{ color: 'red' }}>{errors.name && touched.name ? errors.name : ""}</span>}
                            <TextField
                                id="Account_no"
                                name="account_number"
                                label="Account No"
                                type="number"
                                variant="outlined"
                                fullWidth
                                sx={{
                                    py: 1
                                }}
                                value={values.account_number}
                                onChange={(e) => {
                                    setFieldValue('account_number', e.target.value)
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <BsBank2 />
                                        </InputAdornment>
                                    ),
                                }}

                            />
                            {errors.account_number && <span style={{ color: 'red' }}>{errors.account_number && touched.account_number ? errors.account_number : ""}</span>}
                            <TextField
                                id="IFSC_Code"
                                type="string"
                                name="ifsc_code"
                                label="IFSC Code"
                                variant="outlined"
                                fullWidth
                                sx={{
                                    py: 1
                                }}
                                value={values.ifsc_code}
                                onChange={(e) => {
                                    setFieldValue('ifsc_code', e.target.value)
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <BsBank2 />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {errors.ifsc_code && <span style={{ color: 'red' }}>{errors.ifsc_code && touched.ifsc_code ? errors.ifsc_code : ""}</span>}

                            <TextField
                                id="Bank_Name"
                                type="string"
                                name="bank_name"
                                label="Bank Name"
                                variant="outlined"
                                fullWidth

                                sx={{
                                    py: 1
                                }}
                                value={values.bank_name}
                                onChange={(e) => {
                                    setFieldValue('bank_name', e.target.value)
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <BsBank2 />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {errors.bank_name && <span style={{ color: 'red' }}>{errors.bank_name && touched.bank_name ? errors.bank_name : ""}</span>}

                            <TextField
                                id="Branch_Name"
                                type="string"
                                name="branch_name"
                                label="Branch Name"
                                variant="outlined"
                                fullWidth

                                sx={{
                                    py: 1
                                }}
                                value={values.branch_name}
                                onChange={(e) => {
                                    setFieldValue('branch_name', e.target.value)
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <BsBank2 />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {errors.branch_name && <span style={{ color: 'red' }}>{errors.branch_name && touched.branch_name ? errors.branch_name : ""}</span>}

                        </DialogContent>
                        <DialogActions>
                            <Button loading onClick={onClose} color="error" autoFocus variant="outlined"
                                sx={{
                                    height: 40
                                }}
                                startIcon={<IoClose />}
                            >Close</Button>
                            
                            <Button
                                onClick={handleSubmit}
                                color="success"
                                autoFocus variant="contained"
                                sx={{
                                    height: 40
                                }}
                                startIcon={<IoCheckmarkSharp />}
                            >{values?.id ? 'Update' : 'Add'}
                            </Button>
                            
                        </DialogActions>
                    </Stack>
                )
                }
                </Formik>
        </Dialog>
    )
}
export default DialogForm;