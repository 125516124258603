import axios from 'axios';
import { BASE_URL } from './Api';
import { toast } from 'react-toastify';
import { resetRecoil, setRecoil } from 'recoil-nexus';
import { companyState, loadingState } from '../../Actions';

export const getCompanyData = () => {
    setRecoil(loadingState, {
        type: "screen",
        status: true
    })
    const dataToken = JSON.parse(localStorage.getItem('token'));
    let config = {
        headers: {
            'Authorization': `Bearer ${dataToken}`
        }
    }
    axios.get(`${BASE_URL}/company`, config)
        .then((response) => {
            setRecoil(companyState, response.data.data)
            resetRecoil(loadingState)
        })
        .catch((error) => {
            resetRecoil(loadingState)
            // console.log("Get Contact", error);
        })
}

export const submitdata = (values, actions, onClose) => {

    const tokenData = JSON.parse(localStorage.getItem('token'));

    // console.log(everyData);

    axios.post(`${BASE_URL}/company`, values, {
        headers: {
            'Authorization': `Bearer ${tokenData}`
        }
    })
        .then(function (response) {
            //    console.log(response);
            if (response.data.status) {
                actions.setSubmitting(false)
                actions.resetForm()
                onClose()
                getCompanyData()
                toast.success(response.data.message);
                // setOpenAddModal(false)
                // setapiMessage(response.data.message)
            }
            console.log(response.data.message);
        })
}

export const handleDelete = (id) => {
    const dataToken = JSON.parse(localStorage.getItem('token'));

    axios.delete(`${BASE_URL}/company/${id}`,
        {
            headers: {
                'Authorization': `Bearer ${dataToken}`
            }
        })
        .then(function (response) {
            if (response.data.status) {
                toast.success(response.data.message);
                getCompanyData()
                //actions.resetForm()
            } else {
                toast.error(response.data.message);
            }
            // console.log(response.data.status)

        })
}
