import React from "react";
import { Formik } from 'formik';
import * as Yup from 'yup'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';
import { LoadingButton } from "@mui/lab";   
import { Button, Stack } from '@mui/material';
import { submitdata, handleExpensesTypeEdit } from "../components/Actions/ExpensesTypeAction";

const ExpensesTypeForm = (props) => {
    const { onClose, open, editData } = props;
    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required('Title is required'),
    })
    return (
        <Dialog
            onClose={onClose}
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Formik
                initialValues={{
                    id:editData?.id,
                    title: editData?.title
                }}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit= {(values, actions) => {
                    if(values?.id){
                        handleExpensesTypeEdit(values, actions, onClose)
                    }else{
                        submitdata(values, actions, onClose)
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,

                }) => (
                    <>
                        <DialogTitle id="alert-dialog-title">
                            {"Enter Your Expense Details"}
                        </DialogTitle>
                        <DialogContent>
                            <Stack
                                spacing={2}
                            >
                                <TextField id=" Title"
                                    name="title"
                                    label=" Title"
                                    variant="filled"
                                    type={"string"}
                                    value={values.title}
                                    onChange={(e) => { setFieldValue('title', e.target.value) }}
                                />
                                {errors.title && <span style={{ color: 'red' }}>{errors.title && touched.title ? errors.title : ""}</span>}
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose} color="error">Close</Button>
                            <LoadingButton onClick={handleSubmit} autoFocus color='success' loading={isSubmitting}>
                                Add
                            </LoadingButton>
                        </DialogActions>
                    </>
                )}
            </Formik>
        </Dialog>

    )
}
export default ExpensesTypeForm;