import React, { useState, useEffect } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { LxIcon } from '../../resources/LxIcon';
import { HiOutlinePlusSm } from "react-icons/hi";
import ConfirmDialog from "../../components/DeleteDialog";
import DialogForm from "../../components/PaybleForm";
import { getData, handleDelete } from '../../../src/components/Actions/PayableActions'

export default function BasicTable() {
    const [data, setData] = useState([])
    const [OpenPopup, setOpenPopup] = useState(false);
    const [DeleteId, setDeleteId] = useState('');
    const [openAddModal, setOpenAddModal] = useState(false);
    const [editData, setEditData] = useState({});
    console.log(editData);
    useEffect(() => {
        getData(setData)
    }, [setData])

    return (
        <TableContainer  >
            <Paper elevation={3} >
                <div>
                    <Button variant="outlined"
                        onClick={() => {
                            setEditData({})
                            setOpenAddModal(true)
                        }}
                        color="success"
                        sx={{
                            backgroundColor: "#c7f9f5",
                            marginBottom: 2
                        }}

                        startIcon={<HiOutlinePlusSm />}
                    >
                        create new Payable Account
                    </Button>
                    <DialogForm
                        open={openAddModal}
                        onClose={() => setOpenAddModal(false)}
                        setData={setData}
                        editData={editData}

                    />
                </div>
                <Table aria-label="customized table">
                    <TableHead
                        sx={{
                            border: 0,
                            px: 2,
                            backgroundColor: "#57b8c1",

                        }}
                    >
                        <TableRow >
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Account_no</TableCell>
                            <TableCell align="left">IFSC_Code</TableCell>
                            <TableCell align="left">Bank Name</TableCell>
                            <TableCell align="left">Branch Name</TableCell>
                            <TableCell align="left">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((item, index) =>
                            <TableRow
                                key={index}
                                sx={{ border: 0 }}
                            >
                                <TableCell align="left">{item.name}</TableCell>
                                <TableCell align="left">{item.account_number}</TableCell>
                                <TableCell align="left">{item.ifsc_code}</TableCell>
                                <TableCell align="left">{item.bank_name}</TableCell>
                                <TableCell align="left">{item.branch_name}</TableCell>
                                <TableCell>
                                    <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
                                        <Button onClick={() => {
                                            setEditData(data.find((a) => a.id === item.id))
                                            setOpenAddModal(true)
                                        }}><LxIcon name="FaRegEdit" type="fa" /></Button>
                                        <Button onClick={() => {
                                            setDeleteId(item.id)
                                            setOpenPopup(true)
                                        }}
                                            color="error"><LxIcon name="BiTrash" type="bi" /></Button>
                                    </ButtonGroup>,
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <ConfirmDialog
                    open={OpenPopup}
                    onOpen={() => setOpenPopup(false)}
                    onClick={() => handleDelete(DeleteId, setData)} />
            </Paper>
        </TableContainer>
    );
}
