import React from 'react'
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from './sidebar';
import AppHeader from './header';
import AppFooter from './footer';
import AppLoader from '../../AppLoader';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppLayout = () => {
    return (
        <div>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppHeader />
                <Sidebar />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <DrawerHeader />
                    <Outlet />
                </Box>
            </Box>
            <AppFooter />
            <ToastContainer theme='colored' style={{ width: 'auto' }} />
            <AppLoader />
        </div>
    )
}

export default AppLayout
