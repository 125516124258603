import React, { useState, useEffect } from "react";
import CompanyForm from "../../components/CompanyForm";
import DataTable from 'react-data-table-component';
import { Button, ButtonGroup, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import { LxIcon } from '../../resources/LxIcon';
import { getCompanyData, handleDelete } from '../../components/Actions/CompanyActions';
import ConfirmDialog from "../../components/DeleteDialog";
import { companyState } from "../../Actions";
import { useRecoilValue } from "recoil";
export default function BasicTable() {

    useEffect(() => {
        getCompanyData()
    }, [])

    const companyData = useRecoilValue(companyState)
    const [editData, setEditData] = useState({});
    const [OpenPopup, setOpenPopup] = useState(false);
    const [searchInput, setSearchInput] = React.useState('');
    const [openAddModal, setOpenAddModal] = useState(false);
    const [DeleteId, setDeleteId] = useState('');

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            width: "5%"
        },
        {
            name: 'Company Name',
            selector: row => row.c_name,
            sortable: true,
            width: "27%"
        }, {
            name: 'Company Info',
            selector: row => row.c_info,
            sortable: true,
            width: "27%"
        }, {
            name: 'Action',
            selector: row =>
                <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
                    <Button onClick={() => {
                        setEditData(companyData.find((a) => a.id === row.id))
                        setOpenAddModal(true)
                    }}><LxIcon name="FaRegEdit" type="fa" /></Button>
                    <Button onClick={() => {
                        setDeleteId(row.id)
                        setOpenPopup(true)
                    }}
                        color="error"><LxIcon name="BiTrash" type="bi" /></Button>
                </ButtonGroup>,
            sortable: false,
            width: "10%"
        },
    ]
    const customStyles = {
        header: {
            style: {
                minHeight: '40px',
            },
        },
        headRow: {
            style: {
                height: '10px',
                minHeight: '40px',
                backgroundColor: "#57b8c1"
            },
        },
        headCells: {
            style: {
                fontSize: '15px',
                fontWeight: '700',
                padding: '10px'
            },
        }
    }
    const conditionalRowStyles = [
        {
            when: row => row.UserStat === 'Inactive',
            style: {
                backgroundColor: '#f3f3f3',
                color: 'rgb(132 132 132 / 87%)'
            },
        }
    ]
    const actions = <Stack spacing={2} direction="row">
        <Button
            sx={{
                backgroundColor: "#57b8c1"
            }}
            className='btn-success'
            variant="contained"
            size='small'
            onClick={() => {
                setEditData({})
                setOpenAddModal(true)
            }}
            startIcon={<LxIcon name="FaPlus" type="fa" size={15} />}
        >
            Add New
        </Button>
        <CompanyForm
            onClose={() => setOpenAddModal(false)}
            open={openAddModal}
            editData={editData}
        />
    </Stack>;
    const searchComponent = <OutlinedInput
        placeholder="Search"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        size="small"
        endAdornment={<InputAdornment position="end"><LxIcon name="MdOutlineSearch" type="md" /></InputAdornment>}
        variant="outlined"
    />
    const filteredItems = companyData?.filter(item => (
        item?.c_name.toLowerCase().includes(searchInput.toLowerCase())
    ));
    return (
        <>

            <DataTable
                title='Company Details'
                actions={actions}
                customStyles={customStyles}
                conditionalRowStyles={conditionalRowStyles}
                columns={columns}
                data={filteredItems}
                fixedHeader
                persistTableHead
                subHeader
                subHeaderComponent={searchComponent}
                paginationResetDefaultPage={true}
                pagination={true}
                currentPage={1}
                paginationPerPage={10}

                paginationComponentOptions={{
                    selectAllRowsItem: true,
                    selectAllRowsItemText: 'All'
                }}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
            />
            <ConfirmDialog
                open={OpenPopup}
                onOpen={() => setOpenPopup(false)}
                onClick={() => handleDelete(DeleteId)} />

        </>
    );
}
