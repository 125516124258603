import React from "react";
import { Formik } from 'formik';
import * as Yup from 'yup'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';
import { LoadingButton } from "@mui/lab";
import { Button, Stack } from '@mui/material';
import { submitdata } from './Actions/CompanyActions';

const CompanyForm = (props) => {
    const { onClose, open, editData } = props;
    const validationSchema = Yup.object().shape({
        c_name: Yup.string()
            .required('Company Name is required'),
        c_info: Yup.string()
            .required('Company Inform is required'),
    })
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Formik
                initialValues={{
                    id: editData?.id,
                    c_name: editData?.c_name,
                    c_info: editData?.c_info
                }}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    submitdata(values, actions, onClose)
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    /* and other goodies */
                }) => (
                    <>
                        <DialogTitle id="alert-dialog-title">
                            {"Enter Your Company Details"}
                        </DialogTitle>
                        {/* <span style={{ color: 'red' }}>{apiMessage}</span> */}
                        <DialogContent>
                            <Stack
                                spacing={2}
                            >
                                <TextField id=" Company Name"
                                    name="c_name"
                                    label=" Company Name"
                                    variant="filled"
                                    type={"string"}
                                    value={values.c_name}
                                    onChange={(e) => { setFieldValue('c_name', e.target.value) }}
                                />
                                {errors.c_name && <span style={{ color: 'red' }}>{errors.c_name && touched.c_name ? errors.c_name : ""}</span>}
                                <TextField id="Company Info"
                                    name="c_info"
                                    label=" Company Info"
                                    variant="filled"
                                    type={"string"}
                                    value={values.c_info}
                                    onChange={(e) => { setFieldValue('c_info', e.target.value) }}
                                />
                                {errors.c_info && <span style={{ color: 'red' }}>{errors.c_info && touched.c_info ? errors.c_info : ""}</span>}
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose} color="error">Close</Button>
                            <LoadingButton onClick={handleSubmit} autoFocus color='success' loading={isSubmitting}>
                            {values?.id ? 'Update' : 'Add'}
                            </LoadingButton>
                        </DialogActions>
                    </>
                )}
            </Formik>
        </Dialog>
    )
}
export default CompanyForm;