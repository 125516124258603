import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { useNavigate } from "react-router-dom";
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import { RiMenuUnfoldLine } from 'react-icons/ri';
import { useRecoilState, useRecoilValue } from 'recoil';
import { layoutState ,ProfileState} from '../../../Actions';
import { LxIcon } from '../../../resources/LxIcon';
import { getProfileData, handleLogout } from "../../../components/Actions/AuthAction";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function AppHeader() {

  const [toggleMaenu, setToggleMenu] = useRecoilState(layoutState)
  let navigate = useNavigate();
  const profileData = useRecoilValue(ProfileState);
  // console.log("profile data", profileData); 
  const handleDrawerOpen = () => {
    setToggleMenu(true);
  };

  useEffect(() => {
    getProfileData()
}, [])

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <AppBar position="fixed" open={toggleMaenu}>
      <Container maxWidth="xl">
        <Toolbar disableGutters variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: '36px',
              ...(toggleMaenu && { display: 'none' }),
            }}>
            <RiMenuUnfoldLine />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Welcome to Sysm
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}></Box>

          <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            {/* {profileData?.name} to show user name on header */}
            <h4 style={{ padding: 0, margin: 0 }}>{profileData?.name}</h4>
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar sx={{ width: 32, height: 32 }}>{"A"}</Avatar>
            </IconButton>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                minWidth: 200,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <MenuItem onClick={() => navigate('../ProfileForm')}>
              <Avatar /> Profile
            </MenuItem>
            <MenuItem onClick={()=>handleLogout(navigate)}>
              <ListItemIcon>
                <LxIcon name="MdLogout" type="md" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
