import * as IconsFi from "react-icons/fi";
import * as IconsRi from "react-icons/ri";
import * as IconsIo from "react-icons/io";
import * as IconsMd from "react-icons/md";
import * as IconsGi from "react-icons/gi";
import * as IconsGr from "react-icons/gr";
import * as IconsFa from "react-icons/fa";
import * as IconsIm from "react-icons/im";
import * as IconsBs from "react-icons/bs";
import * as IconsBi from "react-icons/bi";
import * as IconsVsc from "react-icons/vsc";


export const LxIcon = (props) => {

    const { name, type } = props
    
    const FiIcon = IconsFi[name]
    const RiIcon = IconsRi[name]
    const MdIcon = IconsMd[name]
    const GiIcon = IconsGi[name]
    const GrIcon = IconsGr[name]
    const FaIcon = IconsFa[name]
    const ImIcon = IconsIm[name]
    const BsIcon = IconsBs[name]
    const BiIcon = IconsBi[name]
    const VscIcon = IconsVsc[name]
    const IoIcon = IconsIo[name]

    switch (type) {
        case 'fi':
            return <FiIcon  {...props} />;
        case 'ri':
            return <RiIcon  {...props} />;
        case 'md':
            return <MdIcon {...props} />;
        case 'gi':
            return <GiIcon {...props} />;
        case 'gr':
            return <GrIcon {...props} />;
        case 'fa':
            return <FaIcon {...props} />;
        case 'im':
            return <ImIcon {...props} />;
        case 'bs':
            return <BsIcon {...props} />;
        case 'bi':
            return <BiIcon {...props} />;
        case 'vsc':
            return <VscIcon {...props} />;
        case 'io':
            return <IoIcon {...props} />;
        default:
            return <IconsGi.GiBrokenRibbon />;
    }
};
