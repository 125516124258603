import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { LxIcon } from '../../resources/LxIcon';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';
import { height } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Select from '@mui/material/Select';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Dropdown, Selection } from 'react-dropdown-now';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";

import { IoPersonAddSharp, IoPersonRemove } from "react-icons/io5";
import { BiRupee } from "react-icons/bi";
import Fade from '@mui/material/Fade';
import { Stack } from '@mui/material';

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';



export default function FadeMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    function createData(
        Statement_type: string,
        paid_to: string,
        paid_by: string,
        amount: number,
        date: Number,
        notes: string,
    ) {
        return { Statement_type, paid_to, paid_by, amount, date, notes };
    }

    const rows = [
        createData('Expense', "Pranav Das", "Pallab KOle", 7000, 10052022, "Paid succesful"),
        createData('Expense', "Amit Dhank", "Pallab KOle", 200, 25062022, "office tools"),
        createData('Payment', "Sayan chakraborty", "krishanu Koley", 2000, 12042022, "others expense"),
        createData('Payment', "Pranav Das", "Pallab KOle", 7000, 12042022, "Paid succesful"),
        createData('Payment', "Rirtick Patra", "Pallab KOle", 10000, 12042022, "Paid succesful"),
    ];
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [Type, setType] = React.useState("");

    const handleChange = (event) => {
        setType(event.target.value);
    };
    // const handleClickOpen = () => {
    //     setOpen(true);
    // };
    

    

    return (
        <div>
            <Stack


                alignItems={"center"}
                justifyContent={"center"}
            >
                <h1>
                    Your Statement
                </h1>
                <Box sx={{
                    minWidth: 300,
                    backgroundColor: "lightcyan"
                }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">See Your Statement </InputLabel>
                        <Select

                            label="transaction"
                            onChange={handleChange}
                        >
                            <MenuItem value={"Expense"}>Expense</MenuItem>
                            <MenuItem value={"Payment"}>Payment</MenuItem>

                            <MenuItem value={"All"}>All</MenuItem>
                        </Select>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow sx={{
                                        backgroundColor: "#57b8c1"
                                    }}>
                                        <TableCell>Statement Type</TableCell>
                                        <TableCell align="left">Paid To</TableCell>
                                        <TableCell align="left">Paid By</TableCell>
                                        <TableCell align="left">Amount</TableCell>
                                        <TableCell align="left">Date</TableCell>
                                        <TableCell align="left">Notes</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow
                                            key={row.Statement_type}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.Statement_type}
                                            </TableCell>
                                            <TableCell align="left">{row.paid_to}</TableCell>
                                            <TableCell align="left">{row.paid_by}</TableCell>
                                            <TableCell align="left">{row.amount}</TableCell>
                                            <TableCell align="left">{row.date}</TableCell>
                                            <TableCell align="left">{row.notes}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </FormControl>
                </Box>
            </Stack>
        </div>
    );
}
