import React from 'react'
import Button from '@mui/material/Button';
import { Dialog, DialogContent, Stack, Typography } from '@mui/material';

const ConfirmDialog = (props) => {
    const { open, onOpen, onClick } = props

    return (
        <Dialog
            open={open}
            onClose={onOpen}
        >
            <DialogContent sx={{
                height: 200,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <Typography variant='h5'>
                    Do you want to delete it
                </Typography>
                <Stack direction="row" justifyContent="center" spacing={2} sx={{
                    mt: 3
                }}>
                    <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={onOpen}
                    >Cancel</Button>
                    <Button
                        size="small"
                        variant="contained"
                        color="success"
                        onClick={onClick}
                    >Confirm</Button>

                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export default ConfirmDialog