import axios from 'axios';
import { BASE_URL } from './Api';
import { toast } from 'react-toastify';
import { resetRecoil, setRecoil } from 'recoil-nexus';
import { ExpensesTypeState, loadingState } from '../../Actions';

export const getExpensesTypeData = () => {
    setRecoil(loadingState, {
        type: "screen",
        status: true
    })
    const dataToken = JSON.parse(localStorage.getItem('token'));
    let config = {
        headers: {
            'Authorization': `Bearer ${dataToken}`
        }
    }
    axios.get(`${BASE_URL}/expense-type`, config)
        .then((response) => {
            setRecoil(ExpensesTypeState, response.data.data)
            resetRecoil(loadingState)
        })
        .catch((error) => {
            resetRecoil(loadingState)
            // console.log("Get Contact", error);
        })
}

export const submitdata = (values, actions, onClose) => {

    const tokenData = JSON.parse(localStorage.getItem('token'));

    // console.log(everyData);

    axios.post(`${BASE_URL}/expense-type-add`, values, {
        headers: {
            'Authorization': `Bearer ${tokenData}`
        }
    })
        .then(function (response) {
            //    console.log(response);
            if (response.data.status) {
                actions.setSubmitting(false)
                actions.resetForm()
                onClose()
                getExpensesTypeData()
                toast.success(response.data.message);
                // setOpenAddModal(false)
            }
            console.log(response.data.message);
        })
}

export const handleExpensesTypeEdit = (values, actions, onClose) => {
    const tokenData = JSON.parse(localStorage.getItem('token'));
    axios.patch(`${BASE_URL}/expense-type/${values?.id}`, values, {
        headers: {
            'Authorization': `Bearer ${tokenData}`
        }
    })
        .then(function (response) {
            if (response.data.status) {
                actions.resetForm()
                getExpensesTypeData()
                onClose()
                toast.success(response.data.message);
            } 
            console.log(response.data.status)

        }).catch(err => {
            // setError({ name: "Something went wrong" })
            // console.log("Custom Error", err.response.status)
        })
}

export const handleExpensesTypeDelete = (id) => {
    const dataToken = JSON.parse(localStorage.getItem('token'));

    axios.delete(`${BASE_URL}/expense-type/${id}`,
        {
            headers: {
                'Authorization': `Bearer ${dataToken}`
            }
        })
        .then(function (response) {
            if (response.data.status) {
                toast.success(response.data.message);
                getExpensesTypeData()
                //actions.resetForm()
            } else {
                toast.error(response.data.message);
            }
            console.log("testing", response.data.status)

        })
}
