import * as React from 'react';
import CatImage1 from "../resources/images/forgot1.jpg"
import TextField from '@mui/material/TextField';
import { Grid, Paper, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import welImage from "../resources/images/Welcome.png"

export default function BasicTextFields() {

  return (

    <Grid>
      <Stack
        className="body-img1"
        display={"flex"}
        direction={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ height: '100vh' }}>
        <Paper
          elevation={10}
          sx={{
            height: 500,
            borderRadius:5
          }}>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <img
                alt="complex"
                src={CatImage1}
                style={{
                  width: '100%',
                  height: 500,
                  borderRadius:20
                }} />
            </Grid>
            <Grid item xs={6} direction="column">
              <Stack
                spacing={2}
                alignItems="center"
                justifyContent="center"
                sx={{
                  px: 5,
                  height: 500
                }}
              >
                <img
                  alt="just"
                  src={welImage}
                  style={{
                    width: 200,

                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',


                  }}
                />
                <h4>Change Your Password</h4>
                <TextField id="outlined-basic" label="Enter Your Email" variant="outlined" size='small' fullWidth />
                <TextField id="outlined-basic" label="New Password" variant="outlined" size='small' type={"password"} fullWidth />

                <TextField id="outline-basic" label="Confirm password" variant="outlined" size="small" type={"password"} fullWidth />
                {/* <FormControlLabel
            control={
              <Checkbox defaultUnChecked />
            }
            label="I agree With Terms and Condition Apply"
          /> */}
                <Button variant="contained" color='success'
                  sx={{
                    width: 220,
                    height: 40,
                    backgroundColor: "#2da897"

                  }}>Change Password</Button>

                <h5>Already User :<a href="../login">Log In</a></h5>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Stack>
    </Grid>
  );
}