import React from "react";
import { LoadingButton } from "@mui/lab";
import { useFormik } from 'formik';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Button, InputAdornment, Stack } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { BiRupee } from "react-icons/bi";
import moment from 'moment';
import * as Yup from 'yup'
import { submitData, handleProjectEdit } from "../components/Actions/ProjectAction";
import { companyState } from "../Actions";
import { useRecoilValue } from "recoil";
import Grid from '@mui/material/Grid';
import Dropdown from "./Modules/Dropdown";

const ProjectForm = (props) => {
    const { openAddModal, onClose, editData } = props;

    const companyData = useRecoilValue(companyState)
    
    const validationSchema = Yup.object().shape({
        p_name: Yup.string()
            .required('Project name is required'),
        company_id: Yup.object()
            .required('Company name is required')
            .typeError('Company name is required'),
        p_cost: Yup.string()
            .required('Project cost is required'),
        receivable_amount: Yup.string()
            .required('Receivable amount is required'),
        due_amount: Yup.string()
            .required('Due amount is required'),
        confirmation_date: Yup.date()
            .required('Date is required'),
        deadline: Yup.date()
            .required('Date is required'),
        note: Yup.string()
            .required('Note is required'),
    })

    const handleExtSubmit = (values, actions) => {
        console.log("handleExtSubmit", values);
        const data = {
            id: values.id,
            p_name: values.p_name,
            company_id: values.company_id.id,
            c_name: values.company_id.c_name,
            p_cost: values.p_cost,
            receivable_amount: values.receivable_amount,
            due_amount: values.due_amount,
            confirmation_date: values.confirmation_date,
            deadline: values.deadline,
            note: values.note,
        }
        if (values?.id) {
            handleProjectEdit(data, actions, onClose)
        } else {
            submitData(data, actions, onClose)
        }

    }

    const formik = useFormik({
        initialValues: {
            id: editData?.id,
            p_name: editData?.p_name,
            company_id: editData?.company_id ? { company_id: editData?.company_id, c_name: editData.c_name } : null,
            p_cost: editData?.p_cost,
            receivable_amount: editData?.receivable_amount,
            due_amount: editData?.due_amount,
            confirmation_date: editData?.confirmation_date ? editData?.confirmation_date : moment().format('YYYY-MM-DD'),
            deadline: editData?.deadline ? editData?.deadline : moment().format('YYYY-MM-DD'),
            note: editData?.note,
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, actions) => { handleExtSubmit(values, actions) }
    })

    return (
        <Stack spacing={2} direction="row">
            <Dialog
                open={openAddModal}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {"Enter Your Project Details"}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} style={{ marginTop: 10 }}>
                        <Grid item xs={2} md={16}>
                            <TextField
                                id="Project Name"
                                label="Project Name"
                                size='small'
                                fullWidth
                                type={"string"}
                                name={'p_name'}
                                value={formik.values.p_name}
                                onChange={(e) => { formik.setFieldValue('p_name', e.target.value) }}
                            />
                            {formik.errors.p_name && <span style={{ color: 'red' }}>{formik.errors.p_name && formik.touched.p_name ? formik.errors.p_name : ""}</span>}
                        </Grid>
                        <Grid item xs={6} md={16}>
                            <Dropdown
                                label="Company name"
                                options={companyData}
                                getOptionLabel={option => option.c_name}
                                value={formik.values.company_id}
                                onChange={(e, value) => {
                                    formik.setFieldValue('company_id', value);
                                    console.log(value);
                                }}
                            />
                            {formik.errors.company_id && <span style={{ color: 'red' }}>{formik.errors.company_id && formik.touched.company_id ? formik.errors.company_id : ""}</span>}
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <TextField
                                id="Project Cost"
                                label="Project Cost"
                                size='small'
                                type={"number"}
                                name={'p_cost'}
                                value={formik.values.p_cost}
                                onChange={(e) => {
                                    formik.setFieldValue('p_cost', e.target.value)
                                    formik.setFieldValue('due_amount', parseFloat(e.target.value) - parseFloat(formik.values.receivable_amount))
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <BiRupee />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {formik.errors.p_cost && <span style={{ color: 'red' }}>{formik.errors.p_cost && formik.touched.p_cost ? formik.errors.p_cost : ""}</span>}
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <TextField
                                id="Received Amount"
                                label="Received Amount"
                                size='small'
                                type={"number"}
                                name={'receivable_amount'}
                                value={formik.values.receivable_amount}
                                onChange={(e) => {
                                    formik.setFieldValue('receivable_amount', e.target.value)
                                    // Total cost minus with receive amount to 
                                    formik.setFieldValue('due_amount', parseFloat(formik.values.p_cost) - parseFloat(e.target.value))
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {/* <BiRupee /> */}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {formik.errors.receivable_amount && <span style={{ color: 'red' }}>{formik.errors.receivable_amount && formik.touched.receivable_amount ? formik.errors.receivable_amount : ""}</span>}
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <TextField
                                id="Due Amount"
                                label="Due Amount"
                                size='small'
                                type="number"
                                name={'due_amount'}
                                InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {/* <BiRupee /> */}
                                        </InputAdornment>
                                    ),
                                }}
                                value={formik.values.due_amount}
                                onChange={(e) => {
                                    formik.setFieldValue('due_amount', e.target.value)
                                }}
                            />
                            {formik.errors.due_amount && <span style={{ color: 'red' }}>{formik.errors.due_amount && formik.touched.due_amount ? formik.errors.due_amount : ""}</span>}
                        </Grid>

                        <Grid item xs={6} md={6}>
                            <LocalizationProvider dateAdapter={AdapterMoment} >
                                <DesktopDatePicker
                                    label="Date"
                                    name="confirmation_date"
                                    inputFormat="DD-MM-yyyy"
                                    value={formik.values.confirmation_date}
                                    size='small'
                                    onChange={(date) => {
                                        formik.setFieldValue('confirmation_date', moment(date).format('YYYY-MM-DD'))
                                    }}
                                    renderInput={(params) => <TextField {...params} size='small' />}
                                />
                            </LocalizationProvider>
                            {formik.errors.confirmation_date && <span style={{ color: 'red' }}>{formik.errors.confirmation_date && formik.touched.confirmation_date ? formik.errors.confirmation_date : ""}</span>}
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <LocalizationProvider dateAdapter={AdapterMoment} >
                                <DesktopDatePicker
                                    label="Date"
                                    name="deadline"
                                    inputFormat="DD-MM-yyyy"
                                    value={formik.values.deadline}
                                    size='small'
                                    onChange={(date) => {
                                        //console.log(date) 
                                        formik.setFieldValue('deadline', moment(date).format('YYYY-MM-DD'))
                                    }}
                                    renderInput={(params) => <TextField {...params} size='small' />}
                                />
                            </LocalizationProvider>
                            {formik.errors.deadline && <span style={{ color: 'red' }}>{formik.errors.deadline && formik.touched.deadline ? formik.errors.deadline : ""}</span>}
                        </Grid>
                        <Grid item xs={6} md={16}>
                            <TextareaAutosize
                                aria-label="minimum height"
                                minRows={5}
                                placeholder="Note"
                                size='small'
                                style={{ width: '100%' }}
                                name="note"
                                value={formik.values.note}
                                onChange={(e) => { formik.setFieldValue('note', e.target.value) }}
                            />
                            {formik.errors.note && <span style={{ color: 'red' }}>{formik.errors.note && formik.touched.note ? formik.errors.note : ""}</span>}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ marginRight: 20 }}>
                    <Button onClick={onClose} color="error" autoFocus variant="outlined">Close</Button>
                    <LoadingButton
                        onClick={formik.handleSubmit}
                        color="success"
                        autoFocus
                        variant="contained">
                        {formik.values?.id ? 'Update' : 'Add'}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </Stack>
    )
}
export default ProjectForm;