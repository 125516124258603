import React from "react";
import moment from 'moment';
import { Button, InputAdornment, Stack } from '@mui/material';
import MenuItem from "@mui/material/MenuItem";
import { Formik } from 'formik';
import * as Yup from 'yup'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Select from '@mui/material/Select';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import { BiRupee } from "react-icons/bi";
import { submitData } from "../components/Actions/PaymentActions";
import { companyState } from "../Actions";
import { projectState } from "../Actions";
import { useRecoilValue } from "recoil";

const PaymentForm = (props) => {
    const { editData, onClose, openAddModal } = props
    const companyData = useRecoilValue(companyState)
    const ProjectData = useRecoilValue(projectState)
    const validationSchema = Yup.object().shape({
        project_id: Yup.string()
            .required('Project name is required'),
        company_id: Yup.string()
            .required('Company name is required'),
        payment_type: Yup.string()
            .required('Payment type is required'),
        payment_account: Yup.string()
            .required('Account number is required'),
        amount: Yup.string()
            .required('Amount is required'),
        date: Yup.string()
            .required('Date is required'),
        note: Yup.string()
            .required('Note is required'),
    })
    return (
        <Dialog
            onClose={onClose}
            open={openAddModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Formik
                initialValues={{
                    id: editData?.id,
                    payment_type: editData?.payment_type,
                    company_id: editData?.company_id,
                    project_id: editData?.project_id,
                    payment_account: editData?.payment_account ? editData?.payment_account :'',
                    amount: editData?.amount,
                    date: editData?.date ? editData?.date : moment().format('YYYY-MM-DD'),
                    note: editData?.note
                }}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    console.log(values)
                    submitData(values, actions, onClose)

                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    /* and other goodies */
                }) => (
                    <>            <DialogTitle id="alert-dialog-title">
                        {"Enter Your Expense Details"}
                    </DialogTitle>
                        <DialogContent>
                            <Stack
                                spacing={2}
                            >
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Project name</InputLabel>
                                        <Select
                                            label="transaction"
                                            name="project_id"
                                            value={values.project_id}
                                            onChange={(e) => {
                                                setFieldValue('project_id', e.target.value)

                                            }}
                                        >
                                            {ProjectData?.map((project,index) =>
                                                <MenuItem key={index} value={project.id} >{project.p_name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Box>
                                {errors.project_id && <span style={{ color: 'red' }}>{errors.project_id && touched.project_id ? errors.project_id : ""}</span>}



                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Company name</InputLabel>
                                        <Select
                                            label="transaction"
                                            name="company_id"
                                            value={values.company_id}
                                            onChange={(e) => {
                                                setFieldValue('company_id', e.target.value)
                                            }}
                                        >
                                            {companyData?.map((company,index) =>
                                                <MenuItem key={index} value={company.id} >{company.c_name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Box>
                                {errors.company_id && <span style={{ color: 'red' }}>{errors.company_id && touched.company_id ? errors.company_id : ""}</span>}

                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Transaction Type</InputLabel>
                                        <Select
                                            label="transaction"
                                            name="payment_type"
                                            value={values.payment_type}
                                            onChange={(e) => { setFieldValue('payment_type', e.target.value) }}
                                        >
                                            <MenuItem value={"C"} >Credit</MenuItem>
                                            <MenuItem value={"D"}>Debit</MenuItem>

                                        </Select>
                                    </FormControl>
                                </Box>
                                {errors.payment_type && <span style={{ color: 'red' }}>{errors.payment_type && touched.payment_type ? errors.payment_type : ""}</span>}
                                <TextField id="Amount Number"
                                    label=" Account Number"
                                    name="payment_account"
                                    type={"number"}
                                    value={values.payment_account}
                                    onChange={(e) => { setFieldValue('payment_account', e.target.value) }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <BiRupee />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {errors.payment_account && <span style={{ color: 'red' }}>{errors.payment_account && touched.payment_account ? errors.payment_account : ""}</span>}

                                <TextField id="Amount"
                                    label=" Amount"
                                    name="amount"
                                    type={"number"}
                                    value={values.amount}
                                    onChange={(e) => { setFieldValue('amount', e.target.value) }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <BiRupee />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {errors.amount && <span style={{ color: 'red' }}>{errors.amount && touched.amount ? errors.amount : ""}</span>}
                                <LocalizationProvider dateAdapter={AdapterMoment} >
                                    <DesktopDatePicker
                                        label="Date"
                                        name="date"
                                        value={values.date}
                                        onChange={(date) => {
                                            //console.log(date) 
                                            setFieldValue('date', moment(date).format('YYYY-MM-DD'))
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                                {errors.date && <span style={{ color: 'red' }}>{errors.date && touched.date ? errors.date : ""}</span>}

                                <TextareaAutosize
                                    name="note"
                                    value={values.note}
                                    onChange={(e) => { setFieldValue('note', e.target.value) }}
                                    aria-label="minimum height"
                                    minRows={5}
                                    placeholder="Status"
                                    style={{ width: 548 }}
                                />
                                {errors.note && <span style={{ color: 'red' }}>{errors.note && touched.note ? errors.note : ""}</span>}
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose} color="error" variant='contained'>Close</Button>
                            <Button onClick={handleSubmit} autoFocus color='success' variant='contained' >
                                {values?.id ? 'Update' : 'Add'}
                            </Button>
                        </DialogActions>
                    </>

                )}
            </Formik>
        </Dialog>
    )
}
export default PaymentForm;


//8250777864