import axios from 'axios';
import { BASE_URL } from './Api';
import { toast } from 'react-toastify';
import { resetRecoil, setRecoil } from 'recoil-nexus';
import { ExpensesState, loadingState } from '../../Actions';

export const getExpensesData = () => {
    setRecoil(loadingState, {
        type: "screen",
        status: true
    })
    const dataToken = JSON.parse(localStorage.getItem('token'));
    let config = {
        headers: {
            'Authorization': `Bearer ${dataToken}`
        }
    }
    axios.get(`${BASE_URL}/expenses`, config)
        .then((response) => {
            setRecoil(ExpensesState, response.data.data)
            resetRecoil(loadingState)
        })
        .catch((error) => {
            resetRecoil(loadingState)
            // console.log("Get Contact", response.data.data);
        })
}

export const submitdata = (values, actions, onClose) => {

    const tokenData = JSON.parse(localStorage.getItem('token'));

    axios.post(`${BASE_URL}/expenses`, values, {
        headers: {
            'Authorization': `Bearer ${tokenData}`
        }
    })
        .then(function (response) {
              console.log(response);
            if (response.data.status) {
                actions.setSubmitting(false)
                actions.resetForm()
                onClose()
                getExpensesData()
                toast.success(response.data.message);
            }
            //console.log(response.data.message);
        })
}

export const handleEditSubmit = (values, actions, onClose) => {

    const tokenData = JSON.parse(localStorage.getItem('token'));

    axios.patch(`${BASE_URL}/expenses/${values?.id}`, values, {

        headers: {
            'Authorization': `Bearer ${tokenData}`
        }
    })
        .then(function (response) {
            if (response.data.status) {
                actions.resetForm()
                getExpensesData()
                onClose()
                toast.success(response.data.message);
            }
            //console.log(response.data.status)

        }).catch(err => {
            // setError({ name: "Something went wrong" })
            // console.log("Custom Error", err.response.status)
        })
}

export const handleExpensesDelete = (id) => {
    const dataToken = JSON.parse(localStorage.getItem('token'));

    axios.delete(`${BASE_URL}/expenses/${id}`,
        {
            headers: {
                'Authorization': `Bearer ${dataToken}`
            }
        })
        .then(function (response) {
            if (response.data.status) {
                toast.success(response.data.message);
                getExpensesData()
                //actions.resetForm()
            } else {
                toast.error(response.data.message);
            }
            // console.log(response.data.status)

        })
}
