import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import { Button, ButtonGroup, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import { LxIcon } from '../../resources/LxIcon';
import moment from 'moment';
import { getProjectData } from "../../components/Actions/ProjectAction";
import { getCompanyData } from "../../components/Actions/CompanyActions";
import { getPaymentData, handlePaymentDelete } from "../../components/Actions/PaymentActions";
import ConfirmDialog from "../../components/DeleteDialog";
import PaymentForm from "../../components/PaymentForm";
import { useRecoilValue } from "recoil";
import { PaymentlisttState, companyState, projectState } from "../../Actions";

export default function BasicTable() {
    const [editData, setEditData] = useState({});
    const paymentListData = useRecoilValue(PaymentlisttState)
    console.log(paymentListData);
    const [searchInput, setSearchInput] = useState('');
    const [openAddModal, setOpenAddModal] = useState(false);
    const [DeleteId, setDeleteId] = useState('');
    const [OpenPopup, setOpenPopup] = useState(false);

    useEffect(() => {
        getProjectData(projectState)
        getCompanyData(companyState)
        getPaymentData()
    }, [])

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            width: "5%"
        },
        {
            name: 'Date',
            selector: row => moment(row?.date, 'YYYY-MM-DD').format("DD-MM-YYYY"),
            sortable: true,
        }, {
            name: 'Project Name',
            selector: row => row?.project_info?.p_name,
            sortable: true,
        },
        {
            name: 'Company Name',
            selector: row => row?.company_info?.c_name,
            sortable: true,
        },
        {
            name: 'Transaction Type',
            selector: row => row.payment_type,
            sortable: true,
        },
        {
            name: 'Account Number',
            selector: row => row.payment_account,
            sortable: true,
        }, {
            name: 'Amount',
            selector: row => row.amount,
            sortable: true,
        }, {
            name: 'Note',
            selector: row => row.note,
            sortable: true,

        }, {
            name: 'Action',
            selector: (row, index) =>
                <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
                    <Button onClick={() => {
                        setEditData(paymentListData.find((a) => a.id === row.id))
                        setOpenAddModal(true)
                    }}><LxIcon name="FaRegEdit" type="fa" /></Button>
                    <Button onClick={() => {
                        setDeleteId(row.id)
                        setOpenPopup(true)
                    }}
                        color="error"><LxIcon name="BiTrash" type="bi" /></Button>
                </ButtonGroup>,
            sortable: false,
            width: "10%"
        },
    ]

    const customStyles = {
        header: {
            style: {
                minHeight: '40px',
            },
        },
        headRow: {
            style: {
                height: '10px',
                minHeight: '40px',
                backgroundColor: "#57b8c1"
            },
        },
        headCells: {
            style: {
                fontSize: '12px',
                fontWeight: '700',
                padding: '10px'
            },
        }
    }

    const conditionalRowStyles = [
        {
            when: row => row.UserStat === 'Inactive',
            style: {
                backgroundColor: '#f3f3f3',
                color: 'rgb(132 132 132 / 87%)'
            },
        }
    ]

    const actions = <Stack spacing={2} direction="row">
        <Button
            sx={{
                backgroundColor: "#57b8c1"
            }}
            color="success"
            onClick={() => {
                // alert("sss")
                setEditData({})
                setOpenAddModal(true)
            }}
            className='btn-success'
            variant="contained"
            size='small'
            startIcon={<LxIcon name="FaPlus" type="fa" size={15} />}
        >
            Add New Payment
        </Button>

    </Stack>;

    const searchComponent = <OutlinedInput
        placeholder="Search"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        size="small"
        endAdornment={<InputAdornment position="end"><LxIcon name="MdOutlineSearch" type="md" /></InputAdornment>}
        variant="outlined"
    />

    const filteredItems = paymentListData?.filter(item =>
        item?.project_info?.p_name?.toLowerCase().includes(searchInput.toLowerCase()) ||
        item?.payment_account?.toString().toLowerCase().includes(searchInput.toLowerCase())
    );
    return (
        <>
            <DataTable
                aria-label="customized table"
                title='Payment Details'
                actions={actions}
                customStyles={customStyles}
                conditionalRowStyles={conditionalRowStyles}
                columns={columns}
                data={filteredItems}
                fixedHeader
                persistTableHead
                subHeader
                subHeaderComponent={searchComponent}
                paginationResetDefaultPage={true}
                pagination={true}
                currentPage={1}
                paginationPerPage={10}
                paginationComponentOptions={{
                    selectAllRowsItem: true,
                    selectAllRowsItemText: 'All'
                }}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
            />

            <PaymentForm
                editData={editData}
                onClose={() => setOpenAddModal(false)}
                openAddModal={openAddModal}
            />
            
            <ConfirmDialog
                open={OpenPopup}
                onOpen={() => setOpenPopup(false)}
                onClick={() => handlePaymentDelete(DeleteId)} />
        </>

    );
}
